export const DREAMFACTORY_API_KEY: string = 'b7cae590c7c4b5706fc674fa7b2046acd60645efc367cfe79b8514d6ec63b998';
//export const DREAMFACTORY_API_KEY: string = '44d99dfb07fbf030c7664694636bd933579e24c672c5c2aece11eb651ec79fdf';
export const DREAMFACTORY_INSTANCE_URL: string = 'https://sc.poliflex.mx:91/';
export const DREAMFACTORY_DB_SERVICE: string = 'produccion';
export const VERSION: string = 'DEVELOPMENT';
export const SOCKET_SERVER: string = "https://sc.poliflex.mx:5001";
export const SOCKET_SERVER_PRODUCCION: string = "https://sc.poliflex.mx:5002";

// export const DREAMFACTORY_API_KEY: string = 'bfddd353423958d3736750b119973d8adaf0f0e7c9ec11750e81d9676cb28a3b';
// export const DREAMFACTORY_INSTANCE_URL: string = 'https://dmdev.qainformatica.com.mx/';
// export const DREAMFACTORY_DB_SERVICE: string = 'sc';
// export const VERSION: string = 'DEVELOPMENT';
// export const SOCKET_SERVER: string = "http://localhost:5000";
