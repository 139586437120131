import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders
} from '@angular/common/http';

import { Observable } from 'rxjs';
import * as constants from '../config/constants';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {

    constructor() {}

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        //console.log("Interceptor");    
        //let authToken = this.authLocalService.get();
        // Clone the request and replace the original headers with
        // cloned headers, updated with the authorization.
        //console.log(authToken.getValue());
        
        
        const authReq = req.clone({
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                //'X-Dreamfactory-Session-Token': authToken.getValue(),
                'X-Dreamfactory-API-Key': constants.DREAMFACTORY_API_KEY
              })
        });
        
        //authReq.headers.append('X-Dreamfactory-API-Key', constants.DREAMFACTORY_API_KEY)   
        
        //console.log(authReq);
        return next.handle(authReq);
    }
}