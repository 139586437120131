import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./comunicacion/comunicacion.module')
      .then(m => m.ComunicacionModule),
  },
  {
    path: 'produccion',
    loadChildren: () => import('./tablero-produccion/tablero-produccion.module')
      .then(m => m.TableroProduccionModule),
  },

];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
